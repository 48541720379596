import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import DefaultLayout from '../layouts/DefaultLayout'

export default function AboutUs({ data }) {
  const { locale } = useLocalization()
  const page = data[locale]
  const { title, content } = page

  return (
    <DefaultLayout>
      <article
        className={`container mt-24 flex flex-col items-center px-5 xl:px-0 xl:mx-auto`}
      >
        <h1 className="w-full text-2xl xl:text-6xl font-bold leading-tight mb-10 flex justify-between">
          <span>{title}</span>
        </h1>
        <div className="prose max-w-none grid grid-cols-1 xl:grid-cols-1 gap-y-5 xl:gap-10 text-xl xl:text-2xl leading-relaxed">
          <div
            className="order-last xl:order-first"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </article>
    </DefaultLayout>
  )
}

export const query = graphql`
  {
    en: wpPage(slug: { eq: "about-us" }) {
      title
      content
    }

    ar: wpPage(slug: { eq: "%d8%b9%d9%86%d9%86%d8%a7" }) {
      title
      content
    }
  }
`
